import { GET, PUT, POST, DELETE } from '../utils/request';
import { API_PREFIX, CLIENT_ID, MONOLITH_API_PREFIX } from '../consts';

export function sortMealInstances({ restaurantId, mealIds }) {
  return POST(
    `${MONOLITH_API_PREFIX}/api/restaurant/v4/restaurants/${restaurantId}/meal_instances/update_sequences`,
    { ids: mealIds },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function getAllMenuCategorySettings({ restaurantId }) {
  return GET(
    `${MONOLITH_API_PREFIX}/api/restaurant/v2/restaurants/${restaurantId}/menu_categories`,
    {},
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function sortMenuCategories({ restaurantId, menuId, categoryIds }) {
  return POST(
    `${MONOLITH_API_PREFIX}/api/restaurant/v2/restaurants/${restaurantId}/menus/${menuId}/menu_categories/update_sequences`,
    {
      category_ids: categoryIds,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function queryMeals({ restaurant_id, distribution_modes, meal_prototype_ids, page, page_size = 100 }) {
  return GET(
    `${MONOLITH_API_PREFIX}/api/restaurant/v4/restaurants/${restaurant_id}/meal_instances`,
    {
      distribution_modes,
      meal_prototype_ids,
      page,
      page_size,
      valid: false,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function batchUpdateMeals(payload) {
  return PUT(`${API_PREFIX}/v1/meal/price`, payload);
}

export function queryAllCategories(restaurant_id, page) {
  return GET(
    `${MONOLITH_API_PREFIX}/api/restaurant/v2/restaurants/${restaurant_id}/categories`,
    {
      page,
      page_size: 200,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function createCategory({ restaurant_id, ...payload }) {
  return POST(
    `${MONOLITH_API_PREFIX}/api/restaurant/v2/restaurants/${restaurant_id}/categories`,
    { category: payload },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function updateCategory({ restaurant_id, id, ...payload }) {
  return PUT(
    `${MONOLITH_API_PREFIX}/api/restaurant/v2/restaurants/${restaurant_id}/categories/${id}`,
    { category: payload },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function queryAllMenus({ restaurant_id, page }) {
  return GET(
    `${MONOLITH_API_PREFIX}/api/restaurant/v3/restaurants/${restaurant_id}/menus`,
    { page },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function sortPOSMenusSequence(restaurantId, menu_ids) {
  return POST(
    `${MONOLITH_API_PREFIX}/api/restaurant/v2/restaurants/${restaurantId}/menus/update_sequences`,
    {
      ids: menu_ids,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function updateQuotaOfMealInstances(restaurantId, data) {
  return PUT(
    `${MONOLITH_API_PREFIX}/api/restaurant/v4/restaurants/${restaurantId}/meal_instance_quota`,
    { meal_instances: data },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function updateQuotaOfCustomizedOption(restaurantId, data) {
  return PUT(
    `${MONOLITH_API_PREFIX}/api/restaurant/v3/restaurants/${restaurantId}/customized_option_quota`,
    { customized_options: data },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function queryRestaurantCustomizedProperties({ restaurantId, page, page_size }) {
  return GET(
    `${MONOLITH_API_PREFIX}/api/restaurant/v2/restaurants/${restaurantId}/customized_properties`,
    {
      page,
      page_size,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function createRestaurantCustomizedProperties({ restaurant_id, ...data }) {
  return POST(`${MONOLITH_API_PREFIX}/api/restaurant/v2/restaurants/${restaurant_id}/customized_properties`, data, {
    headers: {
      'client-id': CLIENT_ID,
      'service-region-id': 1,
    },
    parseAsJSONApi: true,
  });
}

export function queryRestaurantCustomizedPropertyDetail(restaurantId, customizedPropertyId) {
  return GET(
    `${MONOLITH_API_PREFIX}/api/restaurant/v2/restaurants/${restaurantId}/customized_properties/${customizedPropertyId}`,
    {},
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function updateRestaurantCustomizedProperty({ restaurant_id, customized_property_id, ...data }) {
  return PUT(
    `${MONOLITH_API_PREFIX}/api/restaurant/v2/restaurants/${restaurant_id}/customized_properties/${customized_property_id}`,
    data,
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function deleteRestaurantCustomizedProperty(restaurantId, customizedPropertyId) {
  return DELETE(
    `${MONOLITH_API_PREFIX}/api/restaurant/v2/restaurants/${restaurantId}/customized_properties/${customizedPropertyId}`,
    {},
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function sortCustomizedProperties(restaurantId, customizedPropertyPayload) {
  return POST(
    `${MONOLITH_API_PREFIX}/api/restaurant/v2/restaurants/${restaurantId}/customized_properties/update_position`,
    {
      customized_property: customizedPropertyPayload,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function batchUpdateMealInstancePrice({ restaurantId, meal_instance, operator }) {
  return PUT(
    `${MONOLITH_API_PREFIX}/api/restaurant/v4/restaurants/${restaurantId}/meal_instance_price`,
    { meal_instance, operator },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
      silence: true,
    }
  );
}

export function batchUpdateMealInstanceDisplayColor({ meal_instance }) {
  return PUT(
    `${API_PREFIX}/v1/meal/display/color`,
    { meal_instance },
    {
      headers: {
        'client-id': CLIENT_ID,
      },
      parseAsJSONApi: true,
      silence: true,
    }
  );
}

export function createMealPrototype(payload) {
  return POST(`${API_PREFIX}/v1/meal/dishitem`, payload, {
    parseAsJSONApi: true,
  });
}

export function updateMealPrototype(payload) {
  return PUT(`${API_PREFIX}/v1/meal/dishitem`, payload, {
    parseAsJSONApi: true,
  });
}

export function queryTaxCodes() {
  return GET(
    `${MONOLITH_API_PREFIX}/api/restaurant/v1/tax_codes`,
    {},
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function deleteMeals({ mealIds, mealPrototypeId }) {
  return DELETE(
    `${API_PREFIX}/v1/meal/dishitem`,
    { ids: mealIds, meal_prototype_id: mealPrototypeId },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function updateCategoryDisplayColor({ restaurant_id, menu_id, category_id, display_color }) {
  return PUT(
    `${MONOLITH_API_PREFIX}/api/restaurant/v2/restaurants/${restaurant_id}/categories/update_display_color`,
    {
      menu_id,
      category_id,
      display_color,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function batchUpdateMealsSetting(payload) {
  return PUT(`${API_PREFIX}/v1/meal/setting`, { meal_instance: payload });
}

export function uploadPhoto(formData, source = '') {
  return POST(`${MONOLITH_API_PREFIX}/api/restaurant/v1/upload_images?source=${source}`, formData, {
    headers: {
      'client-id': CLIENT_ID,
      'service-region-id': 1,
    },
  });
}
