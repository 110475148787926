import { cloneDeep } from 'lodash';
import {
  batchUpdateMealInstancePrice,
  batchUpdateMeals,
  createMealPrototype,
  deleteRestaurantCustomizedProperty,
  queryAllMenus,
  queryMeals,
  queryRestaurantCustomizedProperties,
  sortCustomizedProperties,
  sortMealInstances,
  sortPOSMenusSequence,
  updateMealPrototype,
  updateQuotaOfCustomizedOption,
  updateQuotaOfMealInstances,
  updateRestaurantCustomizedProperty,
  batchUpdateMealInstanceDisplayColor,
  queryTaxCodes,
  deleteMeals,
  batchUpdateMealsSetting,
} from '../services/meal';
import { convertTimestampToZonedTime, shouldValidatePermission } from '../utils/utils';
import { sortMenuList } from '../utils/menu';
import {
  CHOWBUS_SERVICE,
  IN_HOUSE_SERVICE,
  MENU_SOURCES_MAPPING,
  CHOWBUS_DISTRIBUTION_MODES,
  IN_HOUSE_DISTRIBUTION_MODES,
} from '../consts';
import { message } from 'antd';
import { numberRangeToArray } from 'src/utils/array';
import { validateMeal } from 'src/components/MenuManagement/MenuBuilder/utils';

const BASE = 'MENU';

export const actions = {
  loadingCustomizationProperties: `${BASE}_LOADING_CUSTOMIZATION_PROPERTIES`,
  loadCustomizationPropertiesSuccess: `${BASE}_LOAD_CUSTOMIZATION_PROPERTIES_SUCCESS`,
  loadCustomizationPropertiesFailed: `${BASE}_LOAD_CUSTOMIZATION_PROPERTIES_FAILED`,

  loadingContractMeals: `${BASE}_LOADING_CONTRACT_MEALS`,
  loadContractMealsSuccess: `${BASE}_LOAD_CONTRACT_MEALS_SUCCESS`,
  loadContractMealsFailed: `${BASE}_LOAD_CONTRACT_MEALS_FAILED`,

  updateServiceMenuDataMapping: `${BASE}_UPDATE_SERVICE_MENU_DATA_MAPPING`,
  updateServiceMenuDataDirtyStatus: `${BASE}_UPDATE_SERVICE_MENU_DATA_DIRTY_STATUS`,
  updateCustomizedProperties: `${BASE}_UPDATE_CUSTOMIZED_PROPERTIES`,
  updatePOSMenuMealsSequence: `${BASE}_UPDATE_POS_MENU_MEALS_SEQUENCE`,
  updateAllMenus: `${BASE}_UPDATE_ALL_MENUS`,
  updateTaxCodes: `${BASE}_UPDATE_TAX_CODES`,
};

export function transformMealData(meals = [], handleRelations = true) {
  return meals.reduce(
    (prev, current) => {
      const {
        category,
        menu,
        meal_prototype,
        restaurant_contract,
        approved_at,
        price,
        member_price,
        relationshipNames,
        customized_options,
        ...extra
      } = current;

      if (!validateMeal(current)) return prev;

      const { distribution_mode } = restaurant_contract;

      if (CHOWBUS_DISTRIBUTION_MODES.includes(distribution_mode)) {
        menu.sequence = CHOWBUS_DISTRIBUTION_MODES.indexOf(distribution_mode) + 1;
      }

      menu.distribution_mode = distribution_mode;

      const formattedPrice = Number(price).toFixed(2);
      let convertedMeal = {
        ...extra,
        name: meal_prototype.name,
        foreign_name: meal_prototype.foreign_name,
        meal_prototype,
        meal_prototype_id: meal_prototype.id,
        menu: menu,
        category,
        quota: 'quota' in current ? current.quota : null,
        price: formattedPrice,
        menu_price: formattedPrice,
        member_price: member_price !== null ? Number(member_price).toFixed(2) : member_price,
        restaurant_contract,
      };

      let existedMenu;
      const categoryPathNames = [];
      const categoryPathForeignNames = [];

      if (menu) {
        existedMenu = prev.categoryTree.find((_) => _.id === menu.id);

        if (handleRelations) {
          categoryPathNames.push(menu.name);
          categoryPathForeignNames.push(menu.foreign_name || menu.name);
        }

        if (!existedMenu) {
          existedMenu = { ...menu, key: menu.id, children: [] };
          prev.categoryTree.push(existedMenu);
        }
      }

      if (category) {
        if (handleRelations) {
          categoryPathNames.push(category.name);
          categoryPathForeignNames.push(category.foreign_name || category.name);
        }

        if (existedMenu && !existedMenu.children.find((_) => _.id === category.id)) {
          existedMenu.children.push({
            ...category,
            key: `${existedMenu.id}_${category.id}`,
          });
        }
      }

      if (handleRelations) {
        const options = (customized_options || []).reduce((prev, current) => {
          const { customized_property, relationshipNames, ...other } = current;
          if (!prev[customized_property.id]) {
            prev[customized_property.id] = {
              ...customized_property,
              customizedOptions: [],
            };
          }

          prev[customized_property.id].customizedOptions.push(other);
          return prev;
        }, {});

        const customizedProperties = Object.values(options)
          .map((customizedProperty) => {
            const { customizedOptions, relationshipNames, ...other } = customizedProperty;
            return { ...other, customized_options: customizedOptions.sort((a, b) => a.position - b.position) };
          })
          .sort((a, b) => a.position - b.position);

        convertedMeal = {
          ...convertedMeal,
          categoryPathNames,
          categoryPathForeignNames,
          customized_properties: customizedProperties,
        };
      }

      prev.meals.push(convertedMeal);
      return prev;
    },
    { categoryTree: [], meals: [] }
  );
}

function getServiceTypeByDistributionMode(distributionMode) {
  return IN_HOUSE_DISTRIBUTION_MODES.includes(distributionMode) ? IN_HOUSE_SERVICE : CHOWBUS_SERVICE;
}

export const fetchAllContactMenus = async ({
  restaurant_id,
  distribution_modes,
  meal_prototype_ids,
  page = 1,
  totalPageData,
} = {}) => {
  let response;
  try {
    response = await queryMeals({ restaurant_id, distribution_modes, meal_prototype_ids, page });
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return response;
  const {
    data,
    meta: { pages },
  } = response;
  const { current_page, total_pages } = pages;
  if (!totalPageData) totalPageData = Array(total_pages).fill(null);
  totalPageData[page - 1] = data;

  if (current_page === 1 && total_pages > current_page) {
    const promises = [];

    for (let i = 2; i <= total_pages; i++) {
      const promise = fetchAllContactMenus({ restaurant_id, distribution_modes, page: i, totalPageData });
      promises.push(promise);
    }

    await Promise.all(promises);
  }

  return { success: true, data: totalPageData };
};

async function fetchMenuPageData({ restaurant_id, page = 1, totalPageData }) {
  let response;
  try {
    response = await queryAllMenus({ restaurant_id, page });
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return response;

  const {
    data,
    meta: { pages },
  } = response;
  const { current_page, total_pages } = pages;
  if (!totalPageData) totalPageData = Array(total_pages).fill(null);
  totalPageData[page - 1] = data;

  if (current_page === 1 && total_pages > current_page) {
    const promises = [];

    for (let i = 2; i <= total_pages; i++) {
      const promise = fetchMenuPageData({ restaurant_id, page: i, totalPageData });
      promises.push(promise);
    }

    await Promise.all(promises);
  }

  return { success: true, data: totalPageData };
}

export const fetchAllMenus = (restaurant_id) => async (dispatch, getState) => {
  const {
    menu: { allMenus },
  } = getState();
  if (allMenus.length) return;

  const response = await fetchMenuPageData({ restaurant_id });
  if (!response.success) return;

  const menus = (response.data || []).reduce((acc, menuArray) => {
    if (!menuArray) return acc;
    menuArray.forEach((menu) => {
      if (!menu.deleted_at) {
        menu.distribution_mode = menu.restaurant_contract?.distribution_mode;
      }
      acc.push(menu);
    });
    return acc;
  });
  sortMenuList(menus);
  dispatch({
    type: actions.updateAllMenus,
    payload: menus,
  });
};

export const fetchContractMeals =
  ({ menuType, restaurantId, onError }) =>
  async (dispatch, getState) => {
    const {
      menu: { isLoadingDishMenus, serviceMenuDataMapping, serviceMenuDataDirtyStatus },
    } = getState();
    if (isLoadingDishMenus) return;

    let serviceData = serviceMenuDataMapping[menuType];
    const isDirty = serviceMenuDataDirtyStatus[menuType];
    if (serviceData && !isDirty) return;

    dispatch({ type: actions.loadingContractMeals });

    const { distribution_modes } = MENU_SOURCES_MAPPING[menuType] || {};
    const response = await fetchAllContactMenus({ restaurant_id: restaurantId, distribution_modes });

    if (!response.success) {
      onError(response);
      dispatch({ type: actions.loadContractMealsFailed });
      return;
    }

    const totalData = (response.data || []).reduce((prev, current) => {
      if (current && current.length) prev.push(...current);
      return prev;
    }, []);

    const newServiceMenuDataMapping = { ...serviceMenuDataMapping };
    serviceData = transformMealData(totalData);
    serviceData.categoryTree.sort((a, b) => a.sequence - b.sequence);
    newServiceMenuDataMapping[menuType] = serviceData;
    const newServiceDirtyStatusMapping = { ...serviceMenuDataDirtyStatus };
    newServiceDirtyStatusMapping[menuType] = false;
    dispatch({
      type: actions.loadContractMealsSuccess,
      payload: {
        serviceMenuDataMapping: newServiceMenuDataMapping,
        serviceMenuDataDirtyStatus: newServiceDirtyStatusMapping,
      },
    });
  };

export const doSortPOSMealInstances =
  ({ restaurantId, mealIds, service, errorCallback }) =>
  async (dispatch, getState) => {
    let response;

    try {
      response = await sortMealInstances({ restaurantId, mealIds });
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) {
      typeof errorCallback === 'function' && errorCallback();
      return;
    }

    const {
      menu: { serviceMenuDataMapping },
    } = getState();
    const newServiceMenuDataMapping = { ...serviceMenuDataMapping };
    const menuData = newServiceMenuDataMapping[service];
    const { meals } = menuData;
    const newMeals = [...meals];
    mealIds.forEach((id, index) => {
      const meal = newMeals.find((_) => _.id === id);
      if (meal) {
        meal.sequence_num = index + 1;
      }
    });
    menuData.meals = newMeals;
    dispatch({
      type: actions.updateServiceMenuDataMapping,
      payload: newServiceMenuDataMapping,
    });
  };

export const doSortPOSMenusSequences =
  ({ restaurantId, menus_ids, service }) =>
  async (dispatch, getState) => {
    let response;
    try {
      response = await sortPOSMenusSequence(restaurantId, menus_ids);
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) return;
    const {
      menu: { serviceMenuDataMapping },
    } = getState();
    const newServiceMenuDataMapping = { ...serviceMenuDataMapping };
    const menuData = newServiceMenuDataMapping[service];
    const { categoryTree } = menuData;
    const newCategoryTree = JSON.parse(JSON.stringify(categoryTree));
    menus_ids.forEach((menuId, index) => {
      const item = newCategoryTree.find((_) => _.id === menuId);
      if (item) item.sequence = index + 1;
    });
    newCategoryTree.sort((a, b) => a.sequence - b.sequence);
    menuData.categoryTree = newCategoryTree;
    dispatch({
      type: actions.updateServiceMenuDataMapping,
      payload: newServiceMenuDataMapping,
    });
  };

function updateQuotaOfMeals({ mealPayload, service, menuDataMapping }) {
  const menuData = menuDataMapping[service];
  if (!menuData) return;
  const { meals } = menuData;
  const newMeals = [...meals];
  mealPayload.forEach(({ id, quota }) => {
    const index = newMeals.findIndex((_) => _.id === id);
    if (index > -1) {
      const clonedMeal = JSON.parse(JSON.stringify(newMeals[index]));
      clonedMeal.quota = quota;
      newMeals[index] = clonedMeal;
    }
  });
  menuData.meals = newMeals;
}

export const doUpdateQuotaOfMealInstances =
  ({ restaurant_id, quotaPayload }, callback) =>
  async (dispatch, getState) => {
    let response;
    try {
      response = await updateQuotaOfMealInstances(restaurant_id, quotaPayload);
    } catch (e) {
      response = { success: false };
    }

    typeof callback === 'function' && callback(response);

    if (!response.success) return;

    const {
      menu: { serviceMenuDataMapping },
    } = getState();
    const newServiceMenuDataMapping = { ...serviceMenuDataMapping };
    const inHouseServiceMeals = [];
    const onlineServiceMeals = [];
    quotaPayload.forEach((item) => {
      if (item.service === IN_HOUSE_SERVICE) {
        inHouseServiceMeals.push(item);
      } else {
        onlineServiceMeals.push(item);
      }
    });

    if (inHouseServiceMeals.length) {
      updateQuotaOfMeals({
        mealPayload: inHouseServiceMeals,
        service: IN_HOUSE_SERVICE,
        menuDataMapping: newServiceMenuDataMapping,
      });
    }

    if (onlineServiceMeals.length) {
      updateQuotaOfMeals({
        mealPayload: onlineServiceMeals,
        service: CHOWBUS_SERVICE,
        menuDataMapping: newServiceMenuDataMapping,
      });
    }

    dispatch({
      type: actions.updateServiceMenuDataMapping,
      payload: newServiceMenuDataMapping,
    });
  };

export const doUpdateQuotaOfCustomizedOptions = (restaurantId, data, callback) => async (dispatch, getState) => {
  let response;
  try {
    response = await updateQuotaOfCustomizedOption(restaurantId, data);
  } catch (e) {
    response = { success: false };
  }
  typeof callback === 'function' && callback(response);

  if (!response.success) return;

  const {
    menu: { customizedProperties },
  } = getState();
  const newCustomizedProperties = cloneDeep(customizedProperties);
  data.forEach((option) => {
    const customizedProperty = newCustomizedProperties.find((_) => _.id === option.customized_property_id);
    if (customizedProperty) {
      const customizedOption = customizedProperty.customized_options.find((item) => item.id === option.id);
      if (customizedOption) customizedOption.quota = option.quota;
    }
  });
  dispatch({
    type: actions.updateCustomizedProperties,
    payload: newCustomizedProperties,
  });
};

async function fetchAllRestaurantCustomizedProperties({ allData = [], page = 1, page_size = 100, restaurantId }) {
  let response;

  try {
    response = await queryRestaurantCustomizedProperties({ restaurantId, page, page_size });
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return response;

  const { data, meta } = response;
  const { total_pages } = meta.pages;
  allData.push(...(data || []));

  if (page === 1 && total_pages > 1) {
    await Promise.all(
      numberRangeToArray(2, total_pages + 1).map((pageNumber) =>
        fetchAllRestaurantCustomizedProperties({
          allData,
          restaurantId,
          page: pageNumber,
          page_size,
        })
      )
    );
  }

  return { success: true, data: allData };
}

export const fetchRestaurantCustomizedProperties =
  (restaurantId, force = false) =>
  async (dispatch, getState) => {
    const {
      menu: { customizedProperties: _existedCustomizedProperties },
    } = getState();
    if (!force && _existedCustomizedProperties.length) return;

    dispatch({ type: actions.loadingCustomizationProperties });

    const response = await fetchAllRestaurantCustomizedProperties({ restaurantId, page: 1 });

    if (!response.success) {
      dispatch({ type: actions.loadCustomizationPropertiesFailed });
      return;
    }

    const customizedProperties = response.data || [];
    customizedProperties.sort((prev, next) => prev.position - next.position);
    customizedProperties.forEach((property) => {
      if (property.customized_options) {
        property.customized_options.sort((a, b) => a.position - b.position);
      }
    });
    dispatch({
      type: actions.loadCustomizationPropertiesSuccess,
      payload: customizedProperties,
    });
  };

export const doUpdateRestaurantCustomizedProperty =
  ({ data, callback, updateCustomizedOptions = false }) =>
  async (dispatch, getState) => {
    let response;
    try {
      response = await updateRestaurantCustomizedProperty(data);
    } catch (e) {
      response = { success: false };
    }

    typeof callback === 'function' && callback(response);

    if (!response.success || !updateCustomizedOptions) return;

    const { customized_property_id, customized_options_attributes } = data;
    const {
      menu: { customizedProperties },
    } = getState();
    const index = customizedProperties.findIndex((_) => _.id === customized_property_id);
    if (index < 0) return;
    const newCustomizedProperties = [...customizedProperties];
    const property = newCustomizedProperties[index];
    customized_options_attributes.forEach((item, idx) => {
      if (item.edited) {
        property.customized_options[idx].menu_price = item.menu_price;
      }
    });
    dispatch({
      type: actions.updateCustomizedProperties,
      payload: newCustomizedProperties,
    });
  };

export const doDeleteRestaurantCustomizedProperty =
  ({ restaurant_id, customized_property_id }, callback) =>
  async (dispatch, getState) => {
    let response;
    try {
      response = await deleteRestaurantCustomizedProperty(restaurant_id, customized_property_id);
    } catch (e) {
      response = { success: false };
    }

    const {
      menu: { customizedProperties },
    } = getState();
    const newCustomizedProperties = [...customizedProperties];
    const index = newCustomizedProperties.findIndex((_) => _.id === customized_property_id);
    if (index > -1) {
      newCustomizedProperties.splice(index, 1);
      dispatch({
        type: actions.updateCustomizedProperties,
        payload: newCustomizedProperties,
      });
    }
    typeof callback === 'function' && callback(response);
  };

export const doBatchUpdateMealPrice =
  ({ restaurantInfo, mealInstancePayload, operator, callback }) =>
  async (dispatch, getState) => {
    let response;
    const shouldValidate = shouldValidatePermission(restaurantInfo);

    const payload = {
      meal_instance: mealInstancePayload,
      operator,
    };
    if (!shouldValidate) payload.restaurantId = restaurantInfo.id;
    const request = shouldValidate ? batchUpdateMeals : batchUpdateMealInstancePrice;

    try {
      response = await request(payload);
    } catch (e) {
      response = { success: false };
    }

    const { errors, success, statusCode } = response;

    if (!success && statusCode !== 422) {
      if (errors && errors.length) {
        message.error(errors.map((_) => _.title).join(', '));
      }

      typeof callback === 'function' && callback({ shouldProcess: false });
      return;
    }

    let errorItems = [];
    const errorMessages = [];

    if (errors) {
      errorItems = errors.reduce((prev, current) => {
        const { title, meta } = current;
        if (meta?.id) {
          prev.push({ id: String(meta.id), reason: title });
        } else {
          errorMessages.push(title);
        }
        return prev;
      }, []);
      const errorMessage = errorMessages.join(',');
      message.error(errorMessage);
    }

    const { serviceMenuDataMapping } = getState().menu;
    const newServiceMenuDataMapping = { ...serviceMenuDataMapping };

    mealInstancePayload.forEach(({ distribution_mode, id, price, pos_is_open_priced, member_price, point_price }) => {
      const service = getServiceTypeByDistributionMode(distribution_mode);
      const newMenuData = newServiceMenuDataMapping[service];
      if (!newMenuData) return;
      const { meals = [] } = newMenuData;
      const newMeals = [...meals];
      const index = newMeals.findIndex((_) => _.id === id);
      if (index < 0) return;
      const mealItem = JSON.parse(JSON.stringify(newMeals[index]));
      const error = errorItems.find((_) => _.id === id);
      if (error) {
        mealItem.change_price_enable = false;
        mealItem.change_price_detail = error.reason;
        mealItem.change_price_business_code = error.code;
        mealItem.price = mealItem.original_price;
        mealItem.member_price = mealItem.origin_member_price;
        mealItem.point_price = mealItem.origin_point_price;
      } else {
        mealItem.original_price = price;
        mealItem.price = price;
        mealItem.member_price = member_price;
        mealItem.point_price = point_price;
        mealItem.origin_member_price = member_price;
        mealItem.origin_point_price = point_price;
        mealItem.updated_at = convertTimestampToZonedTime(new Date());
        if (typeof pos_is_open_priced !== 'undefined') mealItem.pos_is_open_priced = pos_is_open_priced;
      }
      newMeals[index] = mealItem;
      newMenuData.meals = newMeals;
    });

    dispatch({
      type: actions.updateServiceMenuDataMapping,
      payload: newServiceMenuDataMapping,
    });

    typeof callback === 'function' && callback({ errorItems, shouldProcess: true });
  };

export const doBatchUpdateMealDisplayColor =
  ({ mealInstancePayload, callback }) =>
  async (dispatch, getState) => {
    let response;

    const payload = {
      meal_instance: mealInstancePayload,
    };

    try {
      response = await batchUpdateMealInstanceDisplayColor(payload);
    } catch (e) {
      response = { success: false };
    }

    const { errors } = response;
    let errorItems = [];
    const errorMessages = [];

    if (errors) {
      errorItems = errors.reduce((prev, current) => {
        const { title, meta } = current;
        if (meta?.id) {
          prev.push({ id: String(meta.id), reason: title });
        } else {
          errorMessages.push(title);
        }
        return prev;
      }, []);
      const errorMessage = errorMessages.join(',');
      message.error(errorMessage);
    }

    const { serviceMenuDataMapping } = getState().menu;
    const newServiceMenuDataMapping = cloneDeep(serviceMenuDataMapping);

    mealInstancePayload.forEach(({ distribution_mode, id, display_color }) => {
      const service = getServiceTypeByDistributionMode(distribution_mode);
      const newMenuData = newServiceMenuDataMapping[service];
      if (!newMenuData) return;
      const { meals = [] } = newMenuData;
      const mealItem = meals.find((_) => _.id === id);
      if (!mealItem) return;
      const error = errorItems.find((_) => _.id === id);
      if (!error) {
        mealItem.display_color = display_color;
      }
    });

    dispatch({
      type: actions.updateServiceMenuDataMapping,
      payload: newServiceMenuDataMapping,
    });

    typeof callback === 'function' && callback({ errorItems, shouldProcess: true });
  };

export const fetchMealInstanceByMealPrototypeIds = async (payload) => {
  let response;

  try {
    response = await fetchAllContactMenus(payload);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return response;

  let data = [];

  if (response.success) {
    const allData = (response.data || []).reduce((prev, current) => {
      prev.push(...current);
      return prev;
    }, []);
    data = transformMealData(allData).meals;
  }

  return { success: true, data };
};

export const doSortCustomizedProperties = (restaurantId, payload) => async (dispatch, getState) => {
  let response;

  try {
    response = await sortCustomizedProperties(restaurantId, payload);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return;

  const {
    menu: { customizedProperties },
  } = getState();
  const newCustomizedProperties = customizedProperties.map((property) => {
    const item = payload.find((_) => String(_.id) === property.id);
    if (item) {
      property.position = item.position;
    }
    return property;
  });
  newCustomizedProperties.sort((prev, next) => prev.position - next.position);
  dispatch({
    type: actions.updateCustomizedProperties,
    payload: newCustomizedProperties,
  });
};

export const doCreateMealPrototype = (payload, callback) => async (dispatch, getState) => {
  let response;

  try {
    response = await createMealPrototype(payload);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (!response.success) return;
  const {
    menu: { serviceMenuDataDirtyStatus },
  } = getState();
  const newDirtyStatusMapping = { ...serviceMenuDataDirtyStatus };
  Object.keys(newDirtyStatusMapping).forEach((key) => {
    newDirtyStatusMapping[key] = true;
  });
  dispatch({
    type: actions.updateServiceMenuDataDirtyStatus,
    payload: newDirtyStatusMapping,
  });
};

export const doUpdateMealPrototype = (payload, callback) => async (dispatch, getState) => {
  let response;

  try {
    response = await updateMealPrototype(payload);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (!response.success) return;
  const {
    menu: { serviceMenuDataDirtyStatus },
  } = getState();
  const newDirtyStatusMapping = { ...serviceMenuDataDirtyStatus };
  Object.keys(newDirtyStatusMapping).forEach((key) => {
    newDirtyStatusMapping[key] = true;
  });
  dispatch({
    type: actions.updateServiceMenuDataDirtyStatus,
    payload: newDirtyStatusMapping,
  });
};

export const fetchTaxCodes = () => async (dispatch, getState) => {
  const { taxCodes } = getState().menu;
  if (taxCodes.length) return;
  let response;

  try {
    response = await queryTaxCodes();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return;

  dispatch({
    type: actions.updateTaxCodes,
    payload: {
      chowbusTaxCodes: response.data || [],
    },
  });
};

export const doDeleteMeals =
  ({ mealInstances, callback, deleteAll = false }) =>
  async (dispatch, getState) => {
    const ids = mealInstances.map((meal) => meal.id);
    const payload = { mealIds: ids };

    if (deleteAll) {
      payload.mealPrototypeId = mealInstances[0].meal_prototype_id;
    }

    let response;

    try {
      response = await deleteMeals(payload);
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) {
      typeof callback === 'function' && callback(response);
      return;
    }

    const { serviceMenuDataMapping } = getState().menu;
    const newServiceMenuDataMapping = cloneDeep(serviceMenuDataMapping);

    mealInstances.forEach((meal) => {
      const { menu } = meal;
      const service = getServiceTypeByDistributionMode(menu.distribution_mode);
      const newMenuData = newServiceMenuDataMapping[service];
      if (!newMenuData) return;
      const { meals } = newMenuData;
      if (!meals) return;
      const newMeals = [...meals];
      const index = newMeals.findIndex((_) => _.id === meal.id);
      if (index > -1) {
        newMeals.splice(index, 1);
        newMenuData.meals = newMeals;
      }
    });

    dispatch({
      type: actions.updateServiceMenuDataMapping,
      payload: newServiceMenuDataMapping,
    });
    typeof callback === 'function' && callback(response);
  };

export const doBatchUpdateMealsSetting =
  ({ payload, callback, service }) =>
  async (dispatch, getState) => {
    let response;

    try {
      response = await batchUpdateMealsSetting(payload);
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) {
      typeof callback === 'function' && callback(response);
      return;
    }

    const { serviceMenuDataMapping } = getState().menu;
    const newServiceMenuDataMapping = cloneDeep(serviceMenuDataMapping);
    const newMenuData = newServiceMenuDataMapping[service];
    if (!newMenuData) {
      typeof callback === 'function' && callback(response);
      return;
    }

    const { meals } = newMenuData;
    const newMeals = [...meals];

    payload.forEach(({ id, tax_exempt, discount_exempt, tax_code }) => {
      const index = newMeals.findIndex((_) => _.id === id);
      if (index > -1) {
        const clonedMeal = JSON.parse(JSON.stringify(meals[index]));
        if (tax_exempt !== undefined) clonedMeal.tax_exempt = tax_exempt;
        if (discount_exempt !== undefined) clonedMeal.discount_exempt = discount_exempt;
        if (tax_code !== undefined) clonedMeal.tax_code = tax_code;
        newMeals[index] = clonedMeal;
      }
    });
    newMenuData.meals = newMeals;

    dispatch({
      type: actions.updateServiceMenuDataMapping,
      payload: newServiceMenuDataMapping,
    });
    typeof callback === 'function' && callback(response);
  };
