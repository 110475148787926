import Router from 'next/router';
import { MENU_BUILDER_DISTRIBUTION_MODES, MENU_ROUTE_QUERY } from './const';
import { USER_KEYS, getSessionItem } from 'src/store/storage';
import { queryMealsByFilters } from 'src/services/menuBuilder';

const { OPEN_MENU_IDS, OPEN_CHANNEL_IDS, MENU_ID } = MENU_ROUTE_QUERY;
const PRESERVED_PARAMS = [OPEN_MENU_IDS, OPEN_CHANNEL_IDS, MENU_ID];

export function changeMenuBuilderPath(path) {
  const oldQuery = Router.query;
  const query = PRESERVED_PARAMS.reduce((result, param) => {
    result[param] = oldQuery[param];
    return result;
  }, {});
  query.path = path;

  Router.push({
    pathname: Router.pathname,
    query,
  });
}

async function loadMealsPageDataByFilter({ page = 1, pageSize = 100, totalPageData, ...other }) {
  const restaurantId = getSessionItem(USER_KEYS.restaurantId);
  const params = {
    restaurant_id: restaurantId,
    page,
    page_size: pageSize,
    ...other,
  };

  let response;

  try {
    response = await queryMealsByFilters(params);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return response;

  const {
    data,
    meta: { pages },
  } = response;
  const { current_page, total_pages } = pages;
  if (!totalPageData) totalPageData = Array(total_pages).fill(null);
  totalPageData[page - 1] = data;

  if (current_page === 1 && total_pages > current_page) {
    const promises = [];

    for (let i = 2; i <= total_pages; i++) {
      const promise = loadMealsPageDataByFilter({ page: i, pageSize, totalPageData, ...other });
      promises.push(promise);
    }

    await Promise.all(promises);
  }

  return { success: true, data: totalPageData };
}

export async function fetchAllMealsByFilter(payload) {
  return await loadMealsPageDataByFilter({
    ...payload,
    page: 1,
    pageSize: 100,
  });
}

export function sortByContractAndMenuSequence(meals) {
  meals.sort((a, b) => {
    const { menu: menu1 } = a;
    const { menu: menu2 } = b;
    let compare =
      MENU_BUILDER_DISTRIBUTION_MODES.indexOf(menu1.restaurant_contract.distribution_mode) -
      MENU_BUILDER_DISTRIBUTION_MODES.indexOf(menu2.restaurant_contract.distribution_mode);
    if (compare === 0) compare = (menu1.sequence || 0) - (menu2.sequence || 0);
    return compare;
  });
}

export function validateMeal(meal) {
  if (!meal) return false;

  const { approved_at, restaurant_contract, menu } = meal;
  if (!approved_at || !restaurant_contract) return false;
  const { activated_at } = restaurant_contract;
  if (!activated_at || !menu || menu.id === '0') return false;

  return true;
}

export function sortByContractAndMenuId(meals) {
  meals.sort((a, b) => {
    const { menu: menu1 } = a;
    const { menu: menu2 } = b;
    let compare =
      MENU_BUILDER_DISTRIBUTION_MODES.indexOf(menu1.restaurant_contract?.distribution_mode) -
      MENU_BUILDER_DISTRIBUTION_MODES.indexOf(menu2.restaurant_contract?.distribution_mode);
    if (compare === 0) compare = menu1.id - menu2.id;
    if (compare === 0) compare = (menu1.sequence || 0) - (menu2.sequence || 0);
    return compare;
  });
}

export function sortByContractAndMenuAndCategorySequence(meals) {
  meals.sort((a, b) => {
    const { menu: menu1, restaurant_contract: contract1, category: category1 } = a;
    const { menu: menu2, restaurant_contract: contract2, category: category2 } = b;
    let compare =
      MENU_BUILDER_DISTRIBUTION_MODES.indexOf(contract1.distribution_mode) -
      MENU_BUILDER_DISTRIBUTION_MODES.indexOf(contract2.distribution_mode);
    if (compare === 0) compare = (menu1.sequence || 0) - (menu2.sequence || 0);
    if (compare === 0) compare = menu1.id - menu2.id;
    if (compare === 0) compare = (category1.sequence || 0) - (category2.sequence || 0);
    return compare;
  });
}

export function mergeCustomizationColumnsForMeals(meals) {
  const mergeCache = {};
  const firstMealOfMenus = {};
  let menuId;

  meals.forEach((meal) => {
    const { menu } = meal;

    if (!menuId || menu.id !== menuId) {
      menuId = menu.id;
      firstMealOfMenus[menu.id] = meal;
      meal.merged = false;
      meal.rowSpan = 1;
    } else {
      mergeCache[menu.id] = (mergeCache[menu.id] || 1) + 1;
      meal.merged = true;
      meal.rowSpan = 1;
    }
  });

  Object.keys(mergeCache).forEach((menuId) => {
    const rowSpan = mergeCache[menuId] || 1;
    const meal = firstMealOfMenus[menuId];
    if (meal) meal.rowSpan = rowSpan;
  });
}
