export const SEPARATOR = '-';
export const PRICE_PREFIX = 'price';
export const OPEN_PRICE_PREFIX = 'open_price';
export const TAX_CODE_PREFIX = 'tax_code';
export const MEMBER_PRICE_PREFIX = 'member_price';
export const DISCOUNT_EXEMPT_PREFIX = 'discount_exempt';
export const TAX_EXMPT_PREFIX = 'tax_exempt';
export const DEFAULT_UNIT = 'weight_unit';
export const POINT_PRICE_PREFIX = 'point_price';
export const DISPLAY_COLOR_PREFIX = 'display_color';
export const CUSTOMIZATION_VIEW_PREFIX = 'customization_view';
