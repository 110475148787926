import { DISTRIBUTION_MODES } from '../consts';
import { MENU_PAGE_ORITATION_ENUMS, TARGET_DISPLAY_SIZE } from 'src/consts/menuDecorate';
import TabletFieldPathConst from 'src/components/Settings/SmartOrdering/TabletFieldPathConst';
import { getFieldValueByNamePath } from './object';

const { LANDSCAPE, PORTRAIT } = MENU_PAGE_ORITATION_ENUMS;
const { showCategoryForLandscapeMenuDecrationNamePath, showCategoryForPortraitMenuDecorationNamePath } =
  TabletFieldPathConst;

export function sortMenuList(rawMenuList) {
  return rawMenuList.sort((a, b) => {
    const { distribution_mode: mod1, sequence: seq1 } = a;
    const { distribution_mode: mod2, sequence: seq2 } = b;
    if (mod1 === DISTRIBUTION_MODES.SMART_ORDERING && mod2 !== DISTRIBUTION_MODES.SMART_ORDERING) return 1;
    if (mod1 !== DISTRIBUTION_MODES.SMART_ORDERING && mod2 === DISTRIBUTION_MODES.SMART_ORDERING) return -1;
    return seq1 - seq2;
  });
}

export function detectHotspotIntersection(hotspots) {
  if (hotspots.length < 2) return false;

  for (let i = 0; i < hotspots.length - 1; i++) {
    const hotspot1 = hotspots[i];
    const { x: left1, y: top1, width: width1, height: height1 } = hotspot1.position;
    const right1 = left1 + width1;
    const bottom1 = top1 + height1;

    for (let j = i + 1; j < hotspots.length; j++) {
      const hotspot2 = hotspots[j];
      const { x: left2, y: top2, width: width2, height: height2 } = hotspot2.position;
      const right2 = left2 + width2;
      const bottom2 = top2 + height2;

      if (bottom1 < top2 || bottom2 < top1 || left1 > right2 || right1 < left2) continue;
      return true;
    }
  }

  return false;
}

export function extractMealBriefInfo(meal) {
  const { id, meal_prototype, price, member_price, menu, category, point_price, is_open_priced } = meal;
  return {
    id,
    name: meal_prototype.name,
    foreign_name: meal_prototype.foreign_name,
    price,
    member_price,
    menu,
    category,
    point_price,
    is_open_priced,
  };
}

export function checkIfRestaurantEnableTabletPro(restaurantInfo) {
  const { restaurant_contracts = [] } = restaurantInfo;
  if (!restaurant_contracts.length) return false;

  const contract = restaurant_contracts.find((_) => _.distribution_mode === DISTRIBUTION_MODES.SMART_ORDERING);
  if (!contract) return false;

  const { smart_ordering_for_tablet, smart_ordering_for_tablet_pro } = contract;
  return smart_ordering_for_tablet && smart_ordering_for_tablet_pro;
}

const { WIDTH, HEIGHT } = TARGET_DISPLAY_SIZE;

export function getSizeByOrientation(orientation) {
  if (orientation === PORTRAIT) {
    return { width: HEIGHT, height: WIDTH };
  }

  return { width: WIDTH, height: HEIGHT };
}

export function getHotspotsOfDisplayingPage({ pageId, pageInfoMap, componentsMap }) {
  if (!pageId) return [];
  const pageInfo = pageInfoMap[pageId];
  if (!pageInfo) return [];
  const { component_ids } = pageInfo;
  if (!component_ids) return [];
  return component_ids.reduce((prev, id) => {
    const component = componentsMap[id];
    if (component) prev.push(component);
    return prev;
  }, []);
}

export function getParamsFromLocation(location) {
  if (!location) return {};

  const segments = location.split('-');

  if (segments.length === 2) {
    const [menuId, pageId] = segments;
    return { menuId, pageId };
  }

  if (segments.length === 3) {
    const [menuId, categoryId, pageId] = segments;
    return { menuId, categoryId, pageId };
  }

  return {};
}

export function getShowCategoryForCurrentMenuDecorationMode({ orientation, preference }) {
  if (!orientation) return false;
  if (orientation === LANDSCAPE)
    return getFieldValueByNamePath(preference, showCategoryForLandscapeMenuDecrationNamePath);
  if (orientation === PORTRAIT)
    return getFieldValueByNamePath(preference, showCategoryForPortraitMenuDecorationNamePath);
  return false;
}
